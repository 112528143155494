body {
  background-color: #000;
}

.blue-text {
  background-image: linear-gradient(122deg, #ffb6ff, #b344ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grey-text {
  color: grey;
}

span {
  transition: color 0.5s;
}

span.dsa {
  color: rgb(255, 151, 151);
}

.lightgrey-text {
  color: rgb(182, 182, 182);
}

.big-text {
  font-size: 108px;
  line-height: 1.0714285714;
  font-weight: 600;
  letter-spacing: -0.005em;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

.text-center {
  margin: 0 auto;
  max-width: 880px;
}

.welcome-header {
  padding: 200px 20px;
}

.active {
  color: #00ff00;
}

@media screen and (max-width: 860px) {
  .big-text {
    font-size: 100px;
  }
}

@media screen and (max-width: 760px) {
  .big-text {
    font-size: 90px;
  }
}

@media screen and (max-width: 660px) {
  .big-text {
    font-size: 80px;
  }
}

@media screen and (max-width: 560px) {
  .big-text {
    font-size: 70px;
  }
}
